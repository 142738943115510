import { default as algolia } from '../stories/client';

import { client as sanity } from '../../utils/sanity/index.js';

export const init = (Alpine) => {
  Alpine.data('storiesShowcase', component);
};

export const component = ({ query, facetFilters, resourceIds }) => {
  let baseUrl = new URL('/stories', location.origin),
    url = new URL(baseUrl);

  if (facetFilters) {
    Object.entries(facetFilters)?.forEach(([attribute, values]) => {
      values?.forEach((value) => {
        url.searchParams.append(`refinementList[${attribute}][]`, value);
      });
    });
  }
  
  if (query) {
    url.searchParams.append('query', query);
  }

  return {
    url,
    resources: [],
    async init() {
      let resources = resourceIds
        ? await getSanityResources()
        : await getAlgoliaResources();

      this.resources = resources?.map(
        ({ _tags = [], relationships = {}, publishedAt, ...resource }) => ({
          ...resource,
          _createdAt: new Date(publishedAt * 1000),
          terms: Object.entries({ _tags, ...relationships }).flatMap(
            ([attribute, values = []]) =>
              values.filter(Boolean).map((value) => {
                let url = new URL(baseUrl);

                url.searchParams.append(
                  `refinementList[${
                    attribute === '_tags'
                      ? attribute
                      : `relationships.${attribute}`
                  }][]`,
                  value
                );

                return {
                  url,
                  value,
                  key: `${attribute}: ${value}`,
                  available: true
                };
              })
          )
        })
      );
    },
    FacetToggle: function (attribute, value) {
      let url = new URL(baseUrl);

      url.searchParams.append(`menu[${attribute}]`, value);

      return {
        href: url,
        'x-text': () => value
      };
    }
  };

  function getSanityResources() {
    return sanity.fetch(
      `*[_id in $ids]{
          ...,
          "objectID": _id,
          "_createdAt": coalesce(publishedAt, _createdAt),
          "summary": pt::text(coalesce(summary, content[_type == "block"][0])),
          "url": "/stories/" + slug.current,
          image{..., ...asset->},
          "engagementMode": select(
            asset.asset->mimeType in "video/*" => "Watch",
            asset.asset->mimeType in "audio/*" => "Listen",
            "kids" in audience && type in ['link', 'asset'] => "Play",
            "Read"
          ),
          relationships{
            defined(events) => {
              "events": events[]->{
                "title": array::join(array::compact([title, subtitle]), ": ")
              }.title
            },
            defined(creators) => { creators },
            defined(objects) => { objects },
            defined(departments) => { "departments": departments[]->name }
          },
          "_tags": tags[]->name
        }`,
      {
        ids: resourceIds.flatMap((id) => [id, `drafts.${id}`])
      }
    );
  }

  async function getAlgoliaResources() {
    let { hits } = await algolia
      .initIndex('stories')
      .search(query ?? ``, {
        analytics: false,
        hitsPerPage: 4,
        advancedSyntax: true,
        facetFilters:
          facetFilters &&
          Object.entries(facetFilters).flatMap(([attribute, values]) =>
            values.map((value) => `${attribute}:${value}`)
          )
      });

    return hits.map(({ image, lqip, ...resource }) => {
      return {
        ...resource,
        image: {
          url: image,
          metadata: { lqip }
        }
      };
    });
  }
};

export default init;
